import cx from 'classnames';
import type { ComponentProps, ElementType } from 'react';

import styles from './change-case.module.scss';
import type { ChangeCaseProps } from './types';

const ChangeCase = <T extends ElementType = 'div'>(props: ChangeCaseProps<T>) => {
  const { as, casing, className, isInline, ...componentProps } = props;
  const Component = as || 'div';
  const p = {
    ...componentProps,
    className: cx(className, styles[casing], { [styles.inlineBlock]: casing === 'uppercase-first' && isInline }),
  } as ComponentProps<T>;

  return <Component {...p} />;
};

export default ChangeCase;
